export const tabContent = [
    {
        tabId: 1,
        title: "Place your Essay Request",
        src: "/how-it-work/fill-in-the-order-details.webp"
    },
    {
        tabId: 2,
        title: "Carry Out the Payment",
        src: "/how-it-work/complete-the-payment.webp"
    },
    {
        tabId: 3,
        title: "Follow order's progress",
        src: "/how-it-work/monitor-the-order-progress.webp"
    },
    {
        tabId: 4,
        title: "Follow order's progress",
        src: "/how-it-work/monitor-the-order-progress.webp"
    }
];