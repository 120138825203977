import React, { useState } from "react";
// Html Parser
import parse from 'html-react-parser';
import { tabContent } from "./data";
import Image from "next/image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
// scss
import styles from "./service.module.scss";

const Service = (props) => {
  const serviceData = props.serviceData;

  const [currentActiveTab, setCurrentActiveTab] = useState(1);
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }

  return (
    <>
      <section className={styles.services} id="howwork">
        <Container>
          <div className="text-center">
            <h2 className={`section-title ${styles.sectionTitle}`}>
              {serviceData && serviceData.how_it_works_section && serviceData.how_it_works_section.title ? parse(serviceData.how_it_works_section.title.title) : ''}
            </h2>
          </div>
          <div className="col-md-10 m-md-auto">
            <Tab.Container defaultActiveKey={currentActiveTab}>
              <Row className=" align-items-center">
                <Col md={6}>
                  <Nav variant="tabs" className={styles.nav}>
                    {serviceData && serviceData.how_it_works_section && serviceData.how_it_works_section.steps.map((tab, index) => {
                      return (
                        <Nav.Item key={index} className={styles.navItem}>
                          <Nav.Link
                            className={`${currentActiveTab === tab.id ? styles.active : ''} ${styles.servicesTab}`}
                            onClick={() => { toggle(tab.id) }}
                            eventKey={tab.id}
                          >
                            <div className={styles.serviceBox}>
                              <span className={styles.index}>{tab.id}</span>
                              <h3 className={styles.title}>{tab.content_header ? parse(tab.content_header) : ''}</h3>
                              <div className={`${styles.desc} mb-0`}>
                                {tab.content && parse(tab.content)}
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      )
                    })}
                  </Nav>
                </Col>
                <Col md={6}>
                  <Tab.Content>
                    {
                      tabContent.map(({ tabId, title, src }) => (
                          currentActiveTab === tabId && <Tab.Pane eventKey={tabId} key={tabId}>
                          <div className={styles.serviceImg}>
                            <Image
                              src={src}
                              alt="how-it-works-img"
                              title={title}
                              className={styles.serviceImg}
                              width={540}
                              height={299} />
                          </div>
                        </Tab.Pane>
                      ))
                    }
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Service;